import { project } from '@/+state';
import { AvailableProgram } from '@/models/enrollments/available-program.interface';
import {
  Enrollment,
  EnrollmentQuestionAnswer,
} from '@/models/enrollments/enrollment.interface';
import { EnrollmentsProjection } from '@/models/enrollments/enrollments-projection.interface';
import { EnrollmentStatusEnum } from '@/shared/models';
import { Program } from '@/shared/models/survey.interface';
import { handleErrorMessage } from '@/shared/utils';
import { dateFormatter } from '../../../../../shared/date-formatter';
import differenceInDays from 'date-fns/fp/differenceInDays';
import differenceInMonths from 'date-fns/fp/differenceInMonths';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const hasEnrollments = project<boolean>(
  ({ stores }) =>
    !!stores.patientDetailSummaryEnrollmentsStore.enrollments?.length,
);

export const activeProgramCount = project<number>(({ stores }) => {
  return stores.patientDetailSummaryEnrollmentsStore.enrollments.filter(
    program => program.approvalStatus === 'approved',
  ).length;
});

export const enrollmentsLoading = project<boolean>(
  ({ stores }) => stores.patientDetailSummaryEnrollmentsStore.loading,
);

export const enrollments = project<EnrollmentsProjection>(({ stores }) => {
  const datetimeFormat =
    stores.capabilitiesStore.capabilities.international.datetimeFormat;
  return stores.patientDetailSummaryEnrollmentsStore.enrollments
    .map(enrollment => {
      let statusText: string;
      let leftSubtitle: string;
      switch (enrollment.approvalStatus) {
        case EnrollmentStatusEnum.Approved: {
          statusText = 'Enrolled';
          leftSubtitle = `${statusText} ${dateFormatter(
            enrollment.createdAt,
            datetimeFormat,
            'date',
          )}`;
          break;
        }
        case EnrollmentStatusEnum.Rejected: {
          statusText = 'Unenrolled';
          leftSubtitle = `${statusText} ${dateFormatter(
            enrollment.updatedAt,
            datetimeFormat,
            'date',
          )}`;
          break;
        }
        default: {
          break;
        }
      }

      const diffInMonths = differenceInMonths(
        new Date(enrollment.createdAt),
        new Date(),
      );
      const diffInDays = differenceInDays(
        new Date(enrollment.createdAt),
        new Date(),
      );

      let rightSubtitle: string;
      if (diffInMonths >= 2) {
        rightSubtitle = `${diffInMonths} Months In`;
      } else if (diffInDays === 1) {
        rightSubtitle = `${diffInDays} Day In`;
      } else {
        rightSubtitle = `${diffInDays} Days In`;
      }

      enrollment.concepts.forEach(c => {
        c.answers = c.answers.map(answer => {
          //Regular expression to check if the string matches any of the date patterns allowed before applying the dateFormatter() function to avoid unnecessary log errors that would be caused by applying the function on non-date strings.
          const phoneRegex = /^\+?[1-9]\d{1,14}$/;
          const dateRegex = /^(?:(?:(?:\d{2}\/\d{2}\/\d{4})|(?:\d{4}\/\d{2}\/\d{2})|(?:\d{4}-\d{2}-\d{2})))$/;

          if (phoneRegex.test(answer)) {
            const parsed = parsePhoneNumberFromString(answer);
            if (parsed) {
              return parsed.formatInternational();
            }
            return answer; // in case parsing fails, so we don't show mangled data
          }

          if (dateRegex.test(answer)) {
            const answerFormatted = dateFormatter(
              answer,
              datetimeFormat,
              'date',
            );
            return answerFormatted ? answerFormatted : answer;
          }

          return answer;
        });
      });

      return {
        id: enrollment.id,
        name: enrollment.surveyName,
        surveyId: enrollment.surveyAuthoringId,
        enrolledOn: dateFormatter(enrollment.createdAt, datetimeFormat, 'date'),
        updatedAt: dateFormatter(enrollment.updatedAt, datetimeFormat, 'date'),
        status: enrollment.approvalStatus,
        concepts: enrollment.concepts,
        isApproved: enrollment.approvalStatus === EnrollmentStatusEnum.Approved,
        statusText,
        leftSubtitle,
        rightSubtitle,
      };
    })
    .sort((a, b) => {
      return a.name.toUpperCase() <= b.name.toUpperCase()
        ? -1
        : b.name.toUpperCase() < a.name.toUpperCase()
        ? 1
        : 0;
    });
});

export const availablePrograms = project<AvailableProgram[]>(({ stores }) => {
  const enrollmentIDs = stores.patientDetailSummaryEnrollmentsStore.enrollments.map(
    e => e.surveyAuthoringId,
  );

  return stores.patientDetailSummaryEnrollmentsStore.programs
    .reduce((acc, survey) => {
      if (!enrollmentIDs.includes(survey.authoringId)) {
        acc.push({
          name: survey.name,
          description: survey.description,
          id: survey.authoringId,
          visible: true,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => {
      return a.name.toUpperCase() <= b.name.toUpperCase()
        ? -1
        : b.name.toUpperCase() < a.name.toUpperCase()
        ? 1
        : 0;
    });
});

export const selectedProgram = project<Program>(({ stores }) => {
  const program =
    stores.patientDetailSummaryEnrollmentsStore.selectedProgram ||
    ({} as Program);

  program.concepts = (program.concepts || []).map(concept => {
    concept.options?.map(option => {
      option.value = option.conceptIdentifier;
      return option;
    });
    return concept;
  });

  return program;
});

export const enrollmentsError = project<string>(({ stores }) =>
  handleErrorMessage({ store: stores.patientDetailSummaryEnrollmentsStore }),
);

export const selectedEnrolledProgram = project<Program>(({ stores }) => {
  const program =
    stores.patientDetailSummaryEnrollmentsStore.selectedProgram ||
    ({} as Program);
  const enrollment =
    stores.patientDetailSummaryEnrollmentsStore.selectedEnrollment ||
    ({} as Enrollment);

  const mergedConcepts = (program.concepts || []).reduce(
    (acc, concept, index) => {
      const enrollCurr =
        enrollment.concepts.find(
          conc => conc.surveyConceptText === concept.text,
        ) || ({} as EnrollmentQuestionAnswer);
      if (concept.conceptIdentifier === null) {
        concept.conceptIdentifier += `:${index}`;
      }

      if (concept.typeCode === 'checks' || concept.typeCode === 'mult') {
        const value = concept.options.reduce((acc, option) => {
          if (enrollCurr.answers?.includes(option.text)) {
            acc.push(option.conceptIdentifier);
          }
          return acc;
        }, []);

        concept.value = concept.typeCode === 'mult' ? value[0] : value;
      } else {
        concept.value = enrollCurr.answers?.[0];
      }
      acc.push(concept);
      return acc;
    },
    [],
  );

  program.concepts = (mergedConcepts || []).map(concept => {
    concept.options?.map(option => {
      option.value = option.id;
      return option;
    });
    return concept;
  });

  return program;
});

export const selectedEnrollment = project<Enrollment>(
  ({ stores }) =>
    stores.patientDetailSummaryEnrollmentsStore.selectedEnrollment ||
    ({} as Enrollment),
);

export const enrollmentsProjections = [
  hasEnrollments,
  activeProgramCount,
  enrollmentsLoading,
  enrollments,
  availablePrograms,
  selectedProgram,
  enrollmentsError,
  selectedEnrolledProgram,
  selectedEnrollment,
];
