














import { plugins, stores } from '@/+state';
import Header from '@/components/header/Header.vue';
import {
  DialogLauncher,
  DialogProvider,
  DIALOG_TOKEN,
  DialogContainer,
} from '@conversa/bedazzled/src/dialog';
import { InlineLoader } from '@conversa/bedazzled/src/loaders';
import { emit, Reflex, select } from '@conversa/reflex';
import { defineComponent, onMounted, provide } from '@vue/composition-api';
import { activityHandlers } from './+state/activity/handlers';
import { activityProjections } from './+state/activity/projections';
import { capabilitiesProjections } from './+state/capabilities/projections';
import { checkupResponsesHandlers } from './+state/checkup-responses/handlers';
import { checkupResponsesProjections } from './+state/checkup-responses/projections';
import { checkupsHandlers } from './+state/checkups/handlers';
import { checkupsProjections } from './+state/checkups/projections';
import { enrollmentsHandlers } from './+state/enrollments/handlers';
import { enrollmentsProjections } from './+state/enrollments/projections';
import { MiscLoading, AppMounted } from './+state/misc/events';
import { miscHandlers } from './+state/misc/handlers';
import { isMiscLoading, miscProjections } from './+state/misc/projections';
import { patientDetailHandlers } from './+state/patient-details/handlers';
import { patientDetailsProjections } from './+state/patient-details/projections';
import { providersProjections } from './+state/providers/projections';
import { providersHandlers } from './+state/providers/handlers';
import { surveySessionsProjections } from './+state/survey-sessions/projections';
import { surveySessionsHandlers } from './+state/survey-sessions/handlers';
import { userProjections } from './+state/user/projections';
import { userHandlers } from './+state/user/handlers';
import Banner from './components/banner/Banner.vue';
import { bannerProjections } from './+state/banner/projections';
import { bannerHandlers } from './+state/banner/handlers';
import { capabilitiesHandler } from './+state/capabilities/handlers';
import { providerFeaturesHandler } from './+state/capabilities/provider-features/handlers';
import { providerFeaturesProjections } from './+state/capabilities/provider-features/projections';

export default defineComponent({
  name: 'App',
  components: {
    Header,
    InlineLoader,
    DialogLauncher,
    Banner,
  },
  setup() {
    onMounted(() => emit(AppMounted()));
    onMounted(() => emit(MiscLoading({ isLoading: true })));

    const reflex = new Reflex<typeof stores, typeof plugins>({
      projections: [
        ...activityProjections,
        ...capabilitiesProjections,
        ...providerFeaturesProjections,
        ...checkupResponsesProjections,
        ...checkupsProjections,
        ...enrollmentsProjections,
        ...miscProjections,
        ...patientDetailsProjections,
        ...providersProjections,
        ...surveySessionsProjections,
        ...userProjections,
        ...bannerProjections,
      ],
      handlers: [
        ...activityHandlers,
        ...checkupResponsesHandlers,
        ...checkupsHandlers,
        ...enrollmentsHandlers,
        ...miscHandlers,
        ...patientDetailHandlers,
        ...providersHandlers,
        ...surveySessionsHandlers,
        ...userHandlers,
        ...bannerHandlers,
        ...capabilitiesHandler,
        ...providerFeaturesHandler,
      ],
      stores,
      plugins,
      logging: true,
    });

    reflex.init();

    provide(DIALOG_TOKEN, DialogProvider());

    return {
      loading: select(isMiscLoading),
      dialogContainer: DialogContainer,
    };
  },
});
