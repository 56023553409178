import { FeaturesCapabilitiesDto } from '@/+state/+plugins/responses/context-response';
import { ErrorState } from '@/shared/models';
import { store } from '@conversa/reflex';

interface ProviderFeaturesState {
  loading: boolean;
  error: ErrorState;
  providerCode: string;
  features: FeaturesCapabilitiesDto;
}

export const providerFeaturesInitialState: ProviderFeaturesState = {
  loading: false,
  error: null,
  providerCode: null,
  features: {
    alertingEnabled: false,
    smsEnabled: true,
  },
};

export const providerFeaturesStore = store(providerFeaturesInitialState);
